.scoreWrap {
   display: flex;
   flex-flow: column;
   width: 100%;
   height: 80%;
   // -webkit-transition: height 1s ease;
   // -moz-transition: height 1s ease;
   // -o-transition: height 1s ease;
   // -ms-transition: height 1s ease;
   transition: height 0.4s linear;
}

.hight70 {
   height: 70%;
}

.scoreHead {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   height: 10%;
   padding-top: 0.5em;
}

.scoreBody {
   display: flex;
   height: 80%;
}

.scoreToolBar {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 35%;
}

.scoreFoot {
   display: flex;
   justify-content: space-around;
   align-items: center;
   height: 10%;
   // background: grey;
}