.sideMenuBtnWrap {
   position: absolute;
   display: flex;
   align-items: center;
   justify-content: center;
}

.sideMenuBtn {
   color: var(--gray);
   background: var(--black);
   text-align: center;
   box-shadow: 0 0 6px var(--gray);
   cursor: pointer;
}

.posRight {
   right: 0;
   z-index: 2;
   top: 50%;
   bottom: 50%;
}

.posLeft {
   left: 0;
   top: 50%;
   bottom: 50%;
   z-index: 1;
}

.posBottom {
   bottom: 0;
   left: 50%;
   right: 50%;
}

.posRight .sideMenuBtn,
.posLeft .sideMenuBtn {
   padding: 5em 0.4em;
}

.posBottom .sideMenuBtn {
   padding: 0.5em 8em;
   border-radius: 40px 40px 0 0;
   font-size: 0.8em;

}

.posLeft .sideMenuBtn {
   border-radius: 0 40px 40px 0;
}

.posRight .sideMenuBtn {
   border-radius: 40px 0 0 40px;
}

.hide {
   display: none;
}