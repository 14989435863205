.inputWrap {
   display: flex;
   flex-grow: 1;
   align-items: center;
   justify-content: space-between;
   min-width: 50%;
   padding: 0.2em;
}
.inputWrap label {
   font-weight: bold;
   text-shadow: 2px 2px 2px var(--black);
}
.inputWrap input {
   width: 60%;
   padding: 0.3em;
   color: #b2b2b2;
   box-sizing: border-box;
   outline: none;
   border-radius: 5px;
   border: solid 1px var(--gray);
   background: var(--darkgray);
   text-align: center;
   font-family: Arial, Helvetica, sans-serif;
}

.hidden {
   display: none;
}