

.wrap {
   padding: 0.1em;
}
.wrap h2 {
   font-size: 2em;
   margin-block-end: 0;
   margin-block-start: 0;
   color: var(--white);
}
.sport_container {
   padding: 0.5em;
}
.sport_list {
   display: flex;
   flex-flow: row wrap;
   align-items: center;
   justify-content: center;
   padding: 0.5em;
}
.sport_wrap {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   margin: 0.5em;
   padding: 0.5em;
   font-weight: bolder;
   color: var(--white);
   box-shadow: 0 0 3px 0 var(--white);
   border-radius: var(--base-border-radius);
   cursor: pointer;
}
.sport_wrap:hover {
   background: var(--green);
}
.sport_img {
   width: 100px;
}