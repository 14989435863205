


.wrap {
   display: flex;
   align-items: center;
   justify-content: center;
}
.block {
font-size: 5em;
}