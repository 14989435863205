/* GLOBAL */

.Btn_toolSolid {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   padding: 0.5em;
   margin-left: 0.4em;
   border-radius: 10px;
   box-shadow: 0px 3px 2px black;
   background: var(--gray);
   color: var(--white);
   font-size: 1vw;
   width: 5.2vw;
   height: 5.2vw;
   text-align: center;
   cursor: pointer;
}

.Btn_toolSolid svg {
   font-size: 2em;
}

.Btn_toolSolid google-cast-launcher {
   width: 40%;
}


.Btn_toolClear {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   padding: 0.5em;
   margin-left: 0.4em;
   /* border-radius: 10px; */
   /* box-shadow: 0px 3px 2px black; */
   /* background: var(--gray); */
   color: var(--white);
   font-size: 1vw;
   width: 5.2vw;
   text-align: center;
   cursor: pointer;
}

.Btn_toolClear svg {
   font-size: 2em;
}

.Btn_toolClear google-cast-launcher {
   width: 40%;
}


.Btn_tool_grayed {
   background: #2e2c2c;
   border-radius: 10px;
   color: #767676;
}


.testClass {
   box-shadow: 0 0 5px 0 red;
}