.wrap {
   display: flex;
   flex-flow: row wrap;
   padding: 2em;
}

.contentWrap {
   display: flex;
   flex-flow: column;
   width: 40vw;
   min-width: 300px;
   margin: 0.2em;
   padding: 0.5em;
   flex-grow: 1;
   background: #5a5a5a12;
   border-radius: var(--base-border-radius);
}

.contentWrap form {
   max-width: 500px;
}

.contentWrap h3 {
   width: 100%;
   text-align: center;
}

.contentCenter {
   display: flex;
   align-items: center;
   justify-content: center;
}

.castBtnWrap {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100px;
}

.castSettingsWrap {
   width: 30%;
}

.img_wrap {
   display: flex;
   justify-content: center;
   padding: 3em 0;
}

.img_box {
   width: 40%;
}

.cast_box {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 60px;
}

.sync_box,
.sync_wrap,
.device_wrap,
.account_wrap {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   margin-top: 0.5em;
}

.btn_wrap {
   display: flex;
   flex-flow: row;
   align-items: center;
   justify-content: center;
}

.account_img {
   width: 150px;
   height: 100px;
   border-radius: var(--base-border-radius);
   box-shadow: 0 0 5px 0 white;
   background: var(--white);
}

.account_name {
   margin: 0.5em;
   font-weight: bold;
}

// .sync_block {
//    // font-weight: bold;
// }

.sync_block span {
   font-weight: bold;
}

.court_img {
   width: 100px;
   border-radius: var(--base-border-radius);
   margin-bottom: 0.5em;
}

.device_wrap {
   margin: 1em 0;
}