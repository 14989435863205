.wrap {
   display: flex;
   flex-flow: column;
   width: 100%;
   height: 100%;
   background: var(--background);
}

.ScoreTally {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 4vh 0;
}

.AddRow {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 30%;
   // background: red;
}

.BtnRow {
   display: flex;
   align-items: center;
   justify-content: center;
   overflow: hidden;
   // background: red;
}

.BtnRow div {
   margin: 0 0.5em;
}

.clockBox {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 80%;
   font-size: 2em;
   // background: red;
}


.imgWrap {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   font-size: 2em;
   font-weight: bold;
   text-align: center;
   color: var(--white);
}

.leftImg {
   // width: 30.5%;
   // width: 70vw;
   width: 80%;
   border-radius: var(--base-border-radius);
}