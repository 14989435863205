.menuWrap {
   width: 100%;
}

.menuNav {
   display: flex;
   justify-content: flex-end;
   align-items: flex-end;
}

.navBtn {
   margin-right: 0.5em;
   padding: 0.3em 1.5em;
   font-size: 85%;
   font-weight: bold;
   border-radius: 5px 20px 0 0;
   box-shadow: 0 0 5px var(--color-primary-4);
   color: var(--white);
   cursor: pointer;
}

.navBtnActive {
   background: var(--color-primary-1);
   color: var(--white);
}

.tabContent {
   display: none;
   flex-flow: column;
   align-items: center;
   justify-content: center;
}

.tabContent_flexStart {
   align-items: flex-start;
}

.tabContentActive {
   display: flex;
}

.tabBanner {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0.2em 0;
   background: var(--black);
   margin-bottom: 1em;
}

.tabBanner img {
   width: 2em;
   margin-right: 1em;
}