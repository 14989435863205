


.mainWrap {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   font-size: 2em;
   color: var(--white);
   background: var(--background);
}

.imgWrap {
   width: 50%;
}

.imgWrap img {
   width: 100%;
}