.radioSelect {
   display: flex;
   justify-content: flex-end;
}

.radioSelect input[type="radio"] {
   display: none;
}

.radioSelect label {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-left: 5px;
   padding: 10px 5px;
   text-align: center;
   color: var(--white);
   border-radius: 5px;
   border: solid 1px var(--gray);
   background: var(--darkgray);
   cursor: pointer;
}

.radioChecked {
   background: var(--color-primary-1) !important;
}

