


.wrap {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   // width: 50%;
}

.wrap select {
   min-width: 200px;
   padding: 0.5em;
   color: var(--white);
   background: var(--darkgray);
   border: solid 1px var(--gray);
   border-radius: var(--base-border-radius);
   cursor: pointer;
}