


 
 .foot {
   display: flex;
   position: relative;
   align-items: center;
   justify-content: space-around;
   width: 100%;
   height: 10%;
   // background: lightblue;
   transition: height 0.4s linear;
 }


 .hight20 {
   height: 20%;
 }