

.wrap {
   // background: red;
   overflow: auto;
}



.reportTable { 
   border-spacing: 0;
   border-collapse: separate;
   border-radius: 10px;
   border: 1px solid var(--color-primary-1);
}
.reportTable thead th {
   text-align: left;
   background: var(--color-primary-1);
   color: var(--white);
   padding: 0.3em;
   font-size: 1.2em;
   text-align: center;
   white-space: nowrap;
}
.reportHead th {
   background-color: rgb(255, 132, 0) !important;
   text-align: center !important;
   padding: 0.5em !important;
}
.reportColumn { background-color: var(--color-1) !important; }
.reportTableWrap {
   width: 100%;
   overflow-x: auto;
}
.reportTable tbody td { padding: 0.2em; }
.reportTable th, 
.reportTable td {
   text-align: center;
}
.reportTable tr:first-child th:last-child { border-radius: 0 8px 0 0; }
.reportTable tr:first-child th:first-child { border-radius: 8px 0 0 0; }
.reportTable th:not(:last-child),
.reportTable td:not(:last-child) {
 border-right: 1px solid var(--color-primary-1);
}
.reportTable>thead>tr:not(:last-child)>th,
.reportTable>thead>tr:not(:last-child)>td,
.reportTable>tbody>tr:not(:last-child)>th,
.reportTable>tbody>tr:not(:last-child)>td,
.reportTable>tfoot>tr:not(:last-child)>th,
.reportTable>tfoot>tr:not(:last-child)>td,
.reportTable>tr:not(:last-child)>td,
.reportTable>tr:not(:last-child)>th,
.reportTable>thead:not(:last-child),
.reportTable>tbody:not(:last-child),
.reportTable>tfoot:not(:last-child) {
 border-bottom: 1px solid var(--color-primary-1);
}


.formPageNu {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 50%;
}
.flex_center {
   display: flex;
   align-items: center;
   justify-content: center;
}
.items_center {
   text-align: center;
}
.formControl {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   margin-top: 0.5em;
}
.formControl button {
   background: none;
	color: inherit;
	border: none;
	outline: inherit;
   background: var(--color-primary-2);
   padding: 0.5em;
   border-radius: var(--base-border-radius);
   margin: 0 0.2em;
	cursor: pointer;
}


.matchDetails {
   color: var(--white);
   border-radius: var(--base-border-radius);
   background: var(--color-primary-5);
}

.matchDetails_ScoreSheet {
   
   overflow: auto;
}