


.wrap {
   display: flex;
   flex-flow: column;
   align-content: center;
   justify-content: center;

   font-size: 1.2em;
   font-weight: bold;
   color: var(--white);

   // background: red;
}

.wrap span {
   text-align: center;
}