

.scoreItem {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 35%;
   font-size: 18em;
   color: var(--white);
   font-family: "digital-clock";
   text-shadow: 10px 10px 10px var(--black);
}


.scoreItemRight span {
   padding-right: 0.2em;
}
.scoreItemLeft span {
   padding-left: 0.2em;
}
