

.sideMenuWrap {
   display: flex;
   position: fixed;
   flex-flow: column;
   width: 100%;
   height: 100vh;
   transition: -webkit-transform 0.5s ease;
   transition: transform 0.5s ease;
   background: var(--back-transparent);
   backdrop-filter: blur(15px);
   z-index: 1;
}

.menuLeft { left: -110%; }
.menuRight { right: -110%; }

.menuLeft.sideMenuShow {
   -webkit-transform: translateX(110%);
   -ms-transform: translateX(110%);
   transform: translateX(110%);
}

.menuRight.sideMenuShow {
   -webkit-transform: translateX(-110%);
   -ms-transform: translateX(-110%);
   transform: translateX(-110%);
}

.sideMenuHead {
   display: flex;
   width: 100%;
   color: var(--red);
   font-size: 1.5em;
   border-bottom: 1px solid #ffffff78;
}

.menuLeft .sideMenuHead {
   justify-content: flex-end;
}
.menuRight .sideMenuHead {
   justify-content: flex-end;
}

.closeBtn {
   padding: 0.2em 0.5em;
   cursor: pointer;
   font-size: 1.5em;
}

.sideMenuBody {
   display: flex;
   flex-grow: 1;
   align-items: flex-start;
   justify-content: center;
   color: var(--white);
   overflow-y: auto;
}