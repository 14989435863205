

.head {
   display: flex;
   align-items: center;
   justify-content: space-around;
   width: 100%;
   height: 10%;
   // background: red;
}

.nameBlock {
   display: flex;
   font-size: 4vw;
   font-weight: bold;
   color: var(--white);
   // background: red;
}

.score {
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0 1em;
}

.iconBlock {
   font-weight: 900;
   font-size: 5.1vw;
   text-shadow: 1px 1px 1px var(--gray);
   cursor: pointer;
}

.logo img {
   width: 5vw;
}

.name {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 25vw;
   // background: red;
}

.name span {
   white-space: nowrap;
}