

.wrap {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   width: 50%;
}

.wrap input {
   width: 80%;
   margin-right: 1em;
   text-align: center;
   font-family: Arial, Helvetica, sans-serif;
}