.wrap {
   position: absolute;
   bottom: 0;
   right: 0;
   margin-right: 15%;
   font-size: 2.5vw;
   font-weight: bolder;
   color: red;
}

.wrap_hide {
   display: none;
}