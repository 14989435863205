.Wrap {
   display: flex;
   flex-flow: row wrap;
   align-items: center;
   justify-content: center;
   width: 100%;
}

.toolWrap {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   width: 30%;
   height: 12vh;
   border-radius: 10px;
   color: var(--white);
   font-size: 1.8vw;
   margin: 3px;
   cursor: pointer;
}

.toolWrap div {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   width: 95%;
   height: 100%;
   text-align: center;
   text-wrap: wrap;

   // background: red;
}

.showTool {
   box-shadow: 0px 0px 5px 1px var(--black);
}