



.wrapper {
   position: fixed;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   background: var(--back-transparent);
   z-index: 1;
   display: none;
}

.show {
   display: flex;
}