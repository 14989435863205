.matchWrap {
   display: flex;
   flex-flow: column;
   margin-left: 1em;
}

.matchBox {
   display: flex;

}


.gameWrap {
   display: flex;
   flex-flow: column;
   justify-content: flex-start;
   width: 200px;
   min-width: 200px;
   margin-top: 0.5em;
   margin-bottom: 1em;
   box-shadow: 0 0 5px 0 var(--white);
   border-radius: var(--base-border-radius);
}
   
.gameHead {
   display: flex;
   flex-flow: row nowrap;
   padding: 0.3em 0;
   border-bottom: 2px solid black;
}

.gameHead div {
   width: 49%;
   flex-grow: 1;
   border-right: 2px solid black;
   text-align: center;
}

.logWrap {
   display: flex;
   // flex-grow: 1;
}

.logL {
   display: flex;
   width: 50%;
   border-right: 2px solid black;
}

.logR {
   display: flex;
   width: 50%;
   border-left: 2px solid black;
}

.logC {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
}

.logC span {
   background: black;
   padding: 0.2em 0.5em;
   border-radius: var(--base-border-radius);
   text-transform: capitalize;
}


.logPoint {
   display: flex;
   flex-grow: 1;
}

.logPoint span {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 22px;
   height: 22px;
   border-radius: 50%;
   border: 1px solid gray;
   background: black;
   font-size: 0.9em;
}

.logCont {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   flex-grow: 1;
}

.logSpace {
   display: flex;
   flex-grow: 1;
}

.logL .logPoint {
   justify-content: end;
}

.logL .logCont {
   justify-content: flex-end;
}

.gameFoot {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   align-self: flex-end;
   justify-self: end;
   padding-top: 1em;
}

.gameFoot img {
   width: 80%;
   border-radius: var(--base-border-radius);
   margin: 0.5em 0;
   user-select: none;
   -webkit-user-drag: none;
}

.matchTime {
   // background: red;
   text-align: center;
}

.spaceRight {
   padding-right: 3.5em;
}
.spaceLeft {
   padding-left: 3.5em;
}