.wrap {
   display: flex;
   flex-flow: column;
   justify-content: center;
   align-items: flex-end;
   // width: 50%;
}

.slider {
   -webkit-appearance: none;
   appearance: none;
   min-width: 200px;
}

.slider::-webkit-slider-thumb {
   -webkit-appearance: none;
   appearance: none;
   width: 25px;
   height: 25px;
   background: var(--green);
   cursor: pointer;
   border-radius: 2px;
}

.slider::-moz-range-thumb {
   width: 25px;
   height: 25px;
   background: var(--green);
   cursor: pointer;
   border-radius: 2px;
}

.display {
   font-size: 0.9em;
   color: var(--white)
}