


.scoreServe {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 30%;
}

.serveSide {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 4vw;
   font-weight: 900;
   text-shadow: 1px 1px 1px var(--black);
   padding: 0 0.2em 0.05em 0.2em;
   border-radius: 5px;
   color: var(--white);
   cursor: pointer;
}

.serveSideActive {
   // color: var(--player-a-color);
   background: var(--black);
}

.serveIndicatorWrap {
   display: flex;
   align-items: center;
   justify-content: center;
}


.serveIndicator{
   $color: var(--white);
   font-size: 3vw;
   margin: 0 0.2em;
   cursor: pointer;
   filter: 
    drop-shadow(-1px -1px 0px $color) 
    drop-shadow(2px -1px 0px $color) 
    drop-shadow(2px 2px 0px $color)
    drop-shadow(-1px 2px 0px $color);
}





// .saveSideWrap {
//    position: absolute;
//    bottom: -30px;
// }
// .saveSideBtn {
//    width: 120%;
//    text-align: center;
//    padding: 0.5em 0.2em;
//    background: blue;
//    font-size: .2em;
//    border-radius: 5px;
//    cursor: pointer;
// }