.wrap {
   position: fixed;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   background: var(--background);
}
.block {
   display: flex;
   flex-flow: column;
   color: var(--white);
   font-size: 10em;
   padding: 4em;
}