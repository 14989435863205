.wrap {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-flow: column;
   cursor: pointer;
}

.style_1 {
   // width: 55px;
   padding: 0.5em;
   border-radius: var(--base-border-radius);
   background: var(--black);
   box-shadow: 0 0 3px 0 var(--gray);
}