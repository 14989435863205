
.wrap {
   display: flex;
   flex-flow: column;
   width: 100%;
   height: 100%;
   background: var(--background);
}

.head {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-grow: 1;
   width: 100%;
   font-size: 2.5em;
   color: var(--white);
}

.body {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-grow: 20;
   overflow: auto;
}

.matchSettings {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   color: var(--white);
}

.matchSettings p {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 80%;
   margin-block-start: 0;
   margin-block-end: 0;
}

.matchSettings button {
   margin-top: 1em;
}


.addBlock {
   display: flex;
   width: 100%;
   height: 100%;
   padding-right: 0.5em;
}


.centerBlock {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
}

.centerBlock_Space {
   display: flex;
   align-items: flex-start;
   justify-content: center;
   width: 100%;
   font-size: 3vw;
}

.editBtn {
   max-width: 80%;
   border: none;
   background: none;
   color: var(--red);
   font-size: 1.2em;
   box-shadow: 0 0 5px 0 #f5f5f540;
   border-radius: var(--base-border-radius);
   cursor: pointer;
}

.btnSpace {
   width: 25%;
   margin:  3% 4%;
}