
.wrap {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.counterBtn {
   display: flex;
   justify-content: center;
   align-items: center;
   // width: 3vw;
   // height: 3vw;
   width: 40px;
   height: 40px;
   font-size: 1.5em;
   border: 1px solid var(--black);
   border-radius: 50%;
   background: var(--color-primary-1);
   cursor: pointer;
}
