.toolbarWrap {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 90%;
   min-height: 60px;
   margin-top: 1%;
   // background: var(--darkgray);
   // -webkit-transition: all 0.5s ease;
   // -moz-transition: all 0.5s ease;
   // -o-transition: all 0.5s ease;
   // -ms-transition: all 0.5s ease;
   transition: all 0.3s linear;
   position: absolute;
   top: 150%;
}

.isVisible {
   top: 0%;
}

.toolbarBtnWrap {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-grow: 1;
}

.toolbarPinBtnWrap {
   width: 4.2em;
   padding: 0.5em 0;
   text-align: center;
   font-weight: bold;
   transform: rotate(90deg);
   color: var(--white);
   border-radius: 10px;
   background: var(--black);
   cursor: pointer;
}
