@font-face{
  font-family:'digital-clock';
  src: url('../fonts/Digital.otf');
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-backface-visibility: hidden;
}

.App { 
  display: flex;
  flex-grow: 1;
  height: 100vh;
  overflow: hidden;
}



/* SWAL2 */
.swalContainer {
  background: var(--black);
}
.swalPopup {
  background: var(--white);
  box-shadow: 0 0 4px 0 var(--black);
}
.swalDenyButton  {
  font-weight: bold !important;
  color: var(--black) !important;
  background: var(--red);
  box-shadow: 0 0 2px 0 var(--black);
}
.swalConfirmButton {
  font-weight: bold;
  color: var(--black) !important;
  background-color: var(--green);
  box-shadow: 0 0 2px 0 var(--black);
}
.swalCancelButton  {
  font-weight: bold;
  color: var(--black) !important;
  box-shadow: 0 0 2px 0 var(--black);
}

.swalContainer_theme_1 {
  background: red;
}

.swalPopup_theme_1 {
  background: var(--background);
  box-shadow: 0 0 2px 0 white;
}

.swalTitle_theme_1 {
  color: var(--white);
}



@media screen and (orientation: portrait) {
  .App {
    /* Rotate the content container */
    transform: rotate(-90deg);
    transform-origin: left top;
    /* Set content width to viewport height */
    /* width: 100vh; */
    width: 100dvh;
    /* Set content height to viewport width */
    /* height: 100vw; */
    height: 100dvw;

    overflow-x: hidden;
    position: fixed;
    top: 100%;
    left: 0;
  }
}

:root {
  --gray: #444;
  --red: #F27474;
  --green: #A5DC86;
  --orange: #F8BB86;
  --white: #F5F5F5;
  --black: #141414;
  --darkgray: #1A1A1A;
  --light-black: #1a1a1a;
  /* --back-transparent: #0000008f; */
  --back-transparent: #110d2333;
  --back-transparent-glass: #ffffff66;
  --color-primary-1: #103F54;
  --color-primary-2: #5A99B7;
  --color-primary-3: #207EA9;
  --color-primary-4: #78CDF5;
  --color-primary-5: #3e3e3e;
  --player-a-color: #ff0000;
  --player-b-color: #00d8ff;
  --base-border-radius: 5px;
  --background: radial-gradient(at bottom, #5299ff, black);

  --background-dark: radial-gradient(at bottom,#5f5f5f,#000);
  --background-light: radial-gradient(at bottom,#ffffff,#000);
  --background-blue: radial-gradient(at bottom, #5299ff, black);
  --background-green: radial-gradient(at bottom,#2beb4d,#000);
  --background-pink: radial-gradient(at bottom,#ffa5c6,#000);
  --background-purple: radial-gradient(at bottom,#d58eff,#000);
  --background-linear: linear-gradient(90deg, rgba(249,8,8,1) 0%, rgba(0,0,0,1) 50%, rgba(0,211,249,1) 100%);

} 