.wrap {
   display: flex;
   flex-flow: column;
}
.device_container {
   display: flex;
   flex-flow: column;
   align-items: stretch;
   justify-content: flex-start;
   max-height: 85vh;
}
.device_container h2 {
   margin-block-start: 0;
   margin-block-end: 0;
   color: var(--white);
}
.device_wrap {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   width: 70%;
   padding: 0.6em;
   margin: 0.2em;
   color: var(--white);
   box-shadow: 0px 0px 2px 0px black;
   background: var(--color-primary-2);
   border-radius: var(--base-border-radius);
   cursor: pointer;
}

.new_device_box {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   margin-top: 0.4em;
   border-radius: var(--base-border-radius);
   color: var(--black)
}
.new_device_box_input {
   display: flex;
}
.new_device_box_input input {
   padding: 0.6em 2em;
   text-align-last: center;
}
.device_list {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: flex-start;
   overflow: auto;
   width: 100%;
   border-top: 1px solid var(--color-primary-1);
   border-bottom: 1px solid var(--color-primary-1);
}
.new_device_btn {
   padding: 0.5em;
   margin: 0.2em;
   margin-top: 0.8em;
   font-weight: bold;
   font-size: 0.9em;
   border-radius: var(--base-border-radius);
   box-shadow: 0px 0px 2px 0px black;
   color: var(--white);
   background: var(--color-primary-1);
   text-transform: uppercase;
   cursor: pointer;
}
.court_container {
   display: flex;
   flex-flow: row wrap;
   align-items: stretch;
   justify-content: center;
}
.court_wrap {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: flex-start;
   padding: 0.6em;
   margin: 0.2em;
   border-radius: var(--base-border-radius);
   box-shadow: 0px 0px 2px 0px black;
   background: var(--color-primary-4);
   cursor: pointer;
}
.court_wrap span { 
   font-weight: bold;
   margin-bottom: 0.4em;
}
.court_wrap:hover { 
   background: var(--green);
}
.img {
   width: 100px;
   height: 100px;
   border-radius: var(--base-border-radius);
   box-shadow: 0px 0px 5px 0px black;
}