.formWrap {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   width: 100%;
}
.formWrap form {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   width: 100%;
}
.formWrap form span {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
}

.btnBar {
   display: flex;
   align-items: center;
   justify-content: space-around;
   width: 80%;
   margin-top: 1em;
}

.smallBtn { //Remove
   display: flex;
   align-items: center;
   justify-content: center;
   width: 55px;
}

.largeBtn {
   width: 50%;
}

// .soundBtn {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    flex-flow: column;
//    padding: 0.5em;
//    border-radius: var(--base-border-radius);
//    background: var(--black);
//    box-shadow: 0 0 3px 0 var(--gray);
//    cursor: pointer;
// }

.commitBtn {
   width: 100% !important;
   padding: 0.5em;
   font-size: 1.2em;
   text-align: center;
   color: var(--white);
    border-radius: 5px;
   box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 2px 9px 1px rgba(0, 0, 0, 0.12), 0 4px 2px -2px rgba(0, 0, 0, 0.2);
   background: var(--color-primary-1);
   text-transform: uppercase;
   cursor: pointer;
}

.commitBtn:hover {
   box-shadow: 0px 0px 15px 5px rgba(235,235,0,1);
}

.modWrap {
   --color-primary-1: var(--red);
}

.chooseWinnerBox { width: 100%; }
.chooseWinnerBox div { color: black; }