.match_wrap {
   position: relative;
   margin: 2em 0;
   background: var(--color-primary-1);
   border-radius: var(--base-border-radius);
   box-shadow: 0 0 15px 5px var(--color-primary-4);
   cursor: pointer;
}
.info_icon_wrap {
   position: absolute;
   top: 5px;
   right: 5px;
   font-size: 1.8em;
   cursor: pointer;
}

.match_title {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0.1em;
   font-size: 1em;
   font-weight: bold;
   background: var(--color-primary-1);
   border-radius: var(--base-border-radius) var(--base-border-radius) 0 0;
}
.match_title_flat { border-radius: 0; }
.match_title_bottom {
   border-radius: 0 0 var(--base-border-radius) var(--base-border-radius);
}
.match_player_wrap {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
}
.match_player_block {
   display: flex;
   flex-flow: column;
   width: 45%;
}
.match_player_divider {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-grow: 1;
   font-size: 2em;
   font-weight: bold;
   text-shadow: 0 0 10px white;
   color: var(--black);
}
.match_player {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   flex-grow: 1;
   margin: 0.3em;
   padding: 0.5em;
   background: black;
   border-radius: var(--base-border-radius);
}
.match_player img {
   width: 4vw;
   height: 4vw;
   border-radius: var(--base-border-radius);
}
.match_player span {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-grow: 1;
   font-size: 2em;
}
.match_settings_block {
   display: flex;
   align-items: center;
   justify-content: space-evenly;
}
.match_settings_item {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 40%;
   padding: 0.4em;
   margin: 0.2em;
   background: var(--color-primary-1);
   border-radius: var(--base-border-radius);
   background: var(--back-transparent-glass);
}
.match_settings_item_full {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 87%;
   padding: 0.4em;
   margin: 0.2em;
   background: var(--color-primary-1);
   border-radius: var(--base-border-radius);
   background: var(--back-transparent-glass);
}
.match_settings_item span,
.match_settings_item_full span {
   font-weight: bolder;
   margin-right: 0.5em;
}
.loader {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   background: var(--back-transparent);
   border-radius: var(--base-border-radius);
}