.loginBox {
   position: relative;
   display: flex;
   flex-flow: column;
   width: 100%;
   height: 100%;
   background: var(--background);
   border-radius: 5px;
}

.overlayBtn {
   position: absolute;
   top: 2px;
   right: 6px;
   font-size: 1.5em;
   color: var(--darkgray);
   cursor: pointer;
}

.title {
   display: flex;
   flex-flow: row;
   align-items: center;
   justify-content: center;
   font-weight: bold;
   font-size: 5vw;
   // padding: 0.5em 0;
   padding-top: 0.5em;
   color: var(--white);
}
.sub_title {
   display: flex;
   flex-flow: row;
   align-items: center;
   justify-content: center;
   font-weight: bold;
   font-size: 3vw;
   padding: 0 0;
   color: var(--white);
}

.title img {
   width: 50px;
   margin: 0 1em;
}

.form {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   // padding: 1em;
   flex-grow: 5;
}

.form input {
   padding: 0.8em;
   border-radius: 5px;
   margin: 0.5em 0;
   border: 1px solid #e5e5e4;
   transition: border-color .25s ease, box-shadow .25s ease;
   box-sizing: border-box;
}
.form input:focus {
   outline: 0;
   border-color: #1ab188;
}

.img img {
   margin: 0.5em 0;
   width: 10vw;
   // background: red;
}
.foot {
   font-weight: bold;
   color: var(--white);
   cursor: pointer;
}


.loginBtn { background: var(--color-primary-1); }
.registerBtn { background: var(--green); }
.Btn {
   min-width: 100px;
   padding: 0.5em 2em;
   margin: 0.2em 0;
   font-size: 1.2em;
   font-weight: 600;
   text-align: center;
   text-transform: uppercase;
   color: var(--white);
   border-radius: 5px;
   cursor: pointer;

}
.logoutBtn { background: var(--red); }
.loginBtn:hover, .button:focus { background: var(--black); }
.loading { 
   opacity: 0;
   font-size: 0.1em;
 }
.loadingShow {
   opacity: 1;
   margin-top: 0.5em ;
   font-size: 2em;
}