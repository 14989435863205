.wrap {
   border-radius: var(--base-border-radius);
}
// .img_wrap { padding: 0.5em; }

.img_wrap img {
   width: 45vh;
   max-width: 200px;
   border-radius: var(--base-border-radius);
}

.btn_wrap {
   display: flex;
   flex-flow: row wrap;
   align-items: center;
   justify-content: center;
}

.btn {
   width: 40%;
   margin: 0.5em;
   padding: 0.5em;
   font-weight: bolder;
   color: var(--white);
   text-shadow: 0 0 5px var(--black);
   box-shadow: 0 0 2px 0 var(--black);
   border-radius: var(--base-border-radius);
   cursor: pointer;
}

.btn:hover {
   background: var(--green);
}