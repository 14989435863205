.wrap {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   // background: red;
}

.slideMenu {
   background: white;

}

.head {
   display: flex;
   align-items: center;
   justify-content: flex-end;
   border-bottom: 1px solid black;
}

.body {
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: center;
   // background: red;
}



.actionLink {
   font-size: 1.5em;
   font-weight: bold;
   padding: .2em 0;
}
.actionLink:hover {
   color: var(--green);
}


.closeBtn {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 30px;
   height: 30px;
   font-size: 1.5em;
   cursor: pointer;
}