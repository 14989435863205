.wrap {
   width: 90vw;

   margin-bottom: 5em;
}

.title {
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 2em;
   font-weight: bold;
   margin-bottom: 1em;
}

.message {
   color: red;
   padding: 1em;
   font-size: 3em;
   text-align: center;
   background: var(--back-transparent);
   border-radius: var(--base-border-radius);
}