

.wrap {
   display: flex;
   align-items: center;
   justify-content: center;
   // background: red;
   width: 100%;
}

.wrap img {
   width: 80%;
   border-radius: var(--base-border-radius);
}