



.wrap {
   display: flex;
   align-items: center;
   flex-flow: column nowrap;
   justify-content: space-between;
   width: 95%;
}

.blockWrap {
   display: flex;
   flex-flow: row nowrap;
   margin: 0.04em;
   font-size: 6vw;
   width: 100%;
   // background: var(--light-black);
   border-radius: var(--base-border-radius);
   color: var(--white);
}

.icon {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 10%;
   font-weight: 700;
   font-size: 7vw;
   color: var(--player-a-color);
}

.name {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   width: 30%;
   font-size: 4vw;
}

.scoreBlock {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   width: 60%;
}

.scoreItem {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 14%;
   font-family: 'digital-clock';
   border-left: 1px dashed var(--black);
}

.scoreItem:last-child {
   border-right: 1px dashed var(--black);
}

.scoreWon { font-weight: bold; }